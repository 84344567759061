@import 'src/styles/breakpoints';

.Footer {
  margin-top: 100px;
  padding-bottom: 30px;

  .Footer__offer__button {
    font-weight: bold;
  }

  @media (max-width: $screen-sm) {
    margin-top: 15px;
    padding-bottom: 15px;

    .Footer__offer__button {
      margin-top: 13px !important;
      font-weight: bold;
    }
  }

  .Footer__logo {
    width: auto;
    height: auto;
    margin-right: 20px;

    &--bottom {
      margin-bottom: 20px;

      @media (min-width: $screen-sm) {
        margin-right: 0;
      }
    }

    @media (min-width: $screen-sm) {
      max-width: 100%;
    }

    @media (max-width: $screen-sm) {
      margin-right: 10px;
      margin-bottom: 10px;
    }
  }

  .social-links,
  .email-wrapper {
    margin-bottom: 20px;

    @media (max-width: $screen-sm) {
      margin-bottom: 10px;
    }
  }

  .social-link {
    margin-right: 30px;

    img {
      width: 40px;
      height: auto;
    }

    @media (max-width: $screen-sm) {
      margin-right: 15px;
    }
  }

  .Footer__form__email-btn {
    color: #fff;

    &:hover {
      color: #fff !important;
    }
  }

  .Footer__item {
    h5 {
      font-weight: 700;
      font-size: 20px;
      line-height: 25px;
      margin-bottom: 10px;

      @media (max-width: $screen-sm) {
        font-size: 18px;
        margin-bottom: 5px;
        margin-top: 20px;
      }
    }

    p {
      color: #000;
      margin-top: 0;
      margin-bottom: 20px;
      line-height: 1.28;
      font-size: 18px;
      letter-spacing: 0.5px;

      @media (max-width: $screen-sm) {
        margin-bottom: 20px;
        font-size: 16px;
      }
    }

    small {
      color: #808d96;
      font-size: 16px;
      margin-bottom: 20px;

      @media (max-width: $screen-sm) {
        font-size: 14px;
        margin-bottom: 10px;
      }
    }

    ul {
      list-style: none;
      padding: 0;

      li {
        margin-bottom: 10px;

        @media (max-width: $screen-sm) {
          margin-bottom: 5px;
        }
      }
    }
  }

  .Footer__bottom__small {
    display: block;
    width: 65%;

    @media (max-width: $screen-sm) {
      width: 100%;
    }
  }

  .Footer__form {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    position: relative;

    &__button {
      position: relative;
      padding: 10px 20px;
      font-size: 14px;
      margin-top: 20px;

      @media (max-width: $screen-sm) {
        padding: 8px 16px;
        font-size: 12px;
      }
    }

    .Input__wrapper {
      width: 100%;
      padding-bottom: 10px;
    }

    .g-recaptcha {
      margin-top: 10px;
      width: 100%;
      display: flex;
      justify-content: center;
    }
  }

  @media (max-width: $screen-lg) {
    .Footer__logo {
      display: none;
    }
    padding-bottom: 100px;
  }
}
