@import './variables';

.button {
  padding: 10px 28px;
  background-color: $bg-navy-blue;
  color: $color-white;
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 1.7px;
  border: none;
  border-radius: 30px;
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.2s all ease;
  margin: 2.5px;
  img {
    margin-right: 10px;
    width: 16px;
  }
  &:disabled {
    background-color: $bg-gray !important;
    color: $color-white !important;
    cursor: not-allowed !important;
    img {
      filter: brightness(0) invert(100) !important;
    }
  }
  &:hover {
    background-color: $bg-green;
    &:disabled {
      background-color: $bg-gray;
    }
  }

  &.no-margin {
    margin: 0;
  }

  &:visited {
    color: $color-white;
  }
}

.button--red {
  color: $color-white;
  background-color: $bg-red;
  img {
    filter: brightness(0) invert(100);
  }

  &.outlined {
    color: $color-black;
    background: $bg-white;
    img {
      filter: none;
    }

    &:hover {
      color: $color-white;
      background-color: $bg-red;
      img {
        filter: brightness(0) invert(100);
      }
    }
  }
}

.button--white {
  color: $color-black;
  background-color: $bg-white;

  &:hover {
    color: $color-black;
    background-color: $bg-white;
  }
}

.button--green {
  background-color: $bg-green;
}

.arrow-button, .slick-arrow {
  width: 55px;
  height: 55px;
  padding: 0 15px;
  margin: 2px 2px 2px 0;
  color: $color-white;
  border: none;
  border-radius: 100%;
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
  background: url(../assets/images/svg/arrow.svg) $bg-navy-blue;
  background-repeat: no-repeat;
  background-position: center center;

  &.right, &.slick-next {
    transform: rotate(180deg);
  }

  &:disabled {
    background-color: $bg-gray;
  }

  &:hover {
    background-color: $bg-green;
    &:disabled {
      background-color: $bg-gray;
    }
  }
}
