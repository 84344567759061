button,
a {
  cursor: pointer;
}

a {
  text-decoration: none;
  color: #000;
  &:hover, &:visited {
    color: #000;
  }
}

.ta-center {
  text-align: center !important;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}
