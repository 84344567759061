@import 'src/styles/variables';
@import 'src/styles/breakpoints';

.Header {
  height: 100px;
  width: 100%;
  z-index: 1000;
  background: #fff;
  position: fixed;
  box-shadow: 0 0 15px rgb(0 0 0 / 10%);
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: $screen-md) {
    justify-content: center;
    height: 80px;
  }

  .container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 100%;

    @media (max-width: $screen-md) {
      justify-content: center;
    }
  }


  .header__right__block {
    width: 100%;
  }

  .row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    @media (max-width: $screen-md) {
      justify-content: center;
    }
  }

  .Header__btn__heart {
    height: 20px;
    width: 20px;
    margin-right: 5px;
  }



  .Header__info__link {
    color: #000;
    font-size: 18px;
    font-family: 'Source Sans Pro';
    font-weight: 700;
    text-align: center;
    display: block;
    width: 158px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 10px 0;
    box-sizing: border-box;

    &.active {
      position: relative;

      &::after {
        content: '';
        position: absolute;
        width: 100%;
        height: 3px;
        background-color: $bg-navy-blue;
        border-radius: 10px;
        left: 0;
        bottom: -10px;
      }
    }
  }

  .Header__info__link-wrapper__kaz:first-child {
    .Header__info__link {
      width: 100%;
      max-width: 130px;
    }
  }

  .Header__menu-item__link {
    margin: 0 10px;
  }

  .niyet-logo {
    width: 200px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    max-height: 80px;

    @media (max-width: $screen-md) {
      max-width: 150px;
      height: auto;
    }
  }

  .niyet-logo svg {
    width: 100%;
    height: 100%;
    display: block;
    transform: scale(3);

    @media (max-width: $screen-lg) {
      transform: scale(1.8);
    }
  }

  .toggle-menu {
    display: block;
    width: 28px;
    height: 28px;

    span:after,
    span:before {
      content: '';
      position: absolute;
      left: 0;
      top: 9px;
    }

    span:after {
      top: 18px;
    }

    span {
      position: relative;
      display: block;
    }

    span,
    span:after,
    span:before {
      width: 100%;
      height: 2px;
      background-color: #000;
      transition: all 0.3s;
      backface-visibility: hidden;
      border-radius: 2px;
    }

    &.on span {
      background-color: transparent;
    }

    &.on span:before {
      transform: rotate(45deg) translate(-1px, 0px);
    }

    &.on span:after {
      transform: rotate(-45deg) translate(6px, -7px);
    }
  }

  .Header__lock-btn {
    width: 28px;
    height: 28px;
    margin-top: 5px;
  }

  .Header__lang__flag {
    width: 20px;
    height: 20px;
    border-radius: 100px;
    overflow: hidden;
    display: flex;
    margin-right: 10px;

    img {
      margin: 0;
      transition: 0.4s;
      object-fit: cover;
      z-index: 1;
      width: 100%;
      height: auto;
      min-height: 100%;
    }
  }

  .d-none {
    display: none ;
  }

  .d-xl-flex {
    display: flex ;
  }

  .d-grid {
    display: grid ;
  }

  .align-items-center {
    align-items: center ;
  }

  .justify-content-end {
    justify-content: flex-end ;
  }

  .justify-content-center {
    justify-content: center ;
  }

  .button {
    min-width: 135px;
    width: 135px;
    height: 40px;
    font-size: 18px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    box-sizing: border-box;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &:first-child {
      margin-left: 0;
    }

  }

  &.no-help-button {
    .d-xl-flex .d-flex {
      justify-content: flex-end;

      .button {
        &:first-child {
          margin-left: 0;
        }

        & + .button {
          margin-left: 20px;
        }
      }
    }

    &.kaz {
      .button.kaz {
        margin-left: 15px;
      }

      a[href="/profile/beneficiaries"] .button {


        @media(max-width: $screen-md) {
          margin-right: 0;
          margin-left: 0;
        }
      }

      .button:nth-child(2) {

      }

      a[href="/auth/logout"] .button {
        @media(max-width: $screen-md) {
          margin-right: 0;
          margin-left: 0;
        }
      }
    }

    &.ru {
      .button.ru {
        margin-left: 10px;
      }

      a[href="/profile/beneficiaries"] .button {

        @media(max-width: $screen-md) {
          margin-right: 0;
          margin-left: 0;
        }
      }

      .button:nth-child(2) {
        
      }
    }

    &.en {
      .button.en {
        margin-left: 20px;
      }

      a[href="/profile/beneficiaries"] .button {


        @media(max-width: $screen-md) {
          margin-right: 0;
          margin-left: 0;
        }
      }

      .button:nth-child(2) {

      }

      a[href="/auth/logout"] .button {
        @media(max-width: $screen-md) {
          margin-right: 0;
          margin-left: 0;
        }
      }
    }
  }

  &.kaz {
    a[href="/profile/beneficiaries"] .button {
      margin-left: 10px;

      @media (max-width: $screen-md) {
        margin-left: 0;
        margin-right: 0;
      }
    }

    a[href="/auth/login"] .button {
      width: 135px;
      height: 40px;

      @media (max-width: $screen-md) {
        margin-left: 0;
        margin-right: 0;
      }
    }

    a[href="/auth/register"] .button {
      @media (max-width: $screen-md) {
        margin-left: 0;
        margin-right: 0;
      }
    }

    
  }

  &.ru {
    a[href="/profile/beneficiaries"] .button {

    }

    a[href="/auth/login"] .button {
      width: 135px;
      height: 40px;

      @media (max-width: $screen-md) {
        margin-left: 0;
        margin-right: 0;
      }
    }

    a[href="/auth/register"] .button {
      @media (max-width: $screen-md) {
        margin-left: 0;
        margin-right: 0;
      }
    }
  }

  &.en {
    a[href="/profile/beneficiaries"] .button {
      margin-left: 22px;
    }

    a[href="/auth/login"] .button {
      width: 135px;
      height: 40px;

      @media(max-width: $screen-md) {
        margin-left: 0;
        margin-right: 0;
      }
    }

    a[href="/auth/register"] .button {
      @media (max-width: $screen-md) {
        margin-left: 0;
        margin-right: 0;
      }
    }

  }
}
