@import 'src/styles/variables';

.Input__wrapper {
  width: 100%;
  padding-bottom: 24px;
  position: relative;
  display: flex;
  flex-direction: column;

  .Input__wrapper--hint-error {
    margin-bottom: 10px;
  }
}

.Input__wrapper--full {
  width: 100%;
}

.Input__wrapper--no-padding {
  padding: 0;
}

.Input {
  margin-bottom: 4px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-color: $bg-gray-input;
  background: $bg-gray-input;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  color: $color-black !important;
  padding: 8px 12px;
  outline: none;
  -ms-touch-action: none !important;
  touch-action: none !important;

  &:focus,
  &:hover {
    border-color: $color-info;
  }

  &:disabled {
    border-color: $bg-gray;
    cursor: not-allowed;
  }
}

.Input--error {
  border-color: $bg-red;
}

.Input__error {
  color: $color-red;
  font-size: 14px;
  position: absolute;
  bottom: -7px;
  left: 10px;
}

.Input__hint {
  font-size: 14px;
  position: absolute;
  bottom: -7px;
  left: 10px;

  &.Input__hint--with-error {
    bottom: -24px;
  }
}

.Input__label {
  margin-bottom: 3px;
}

.Inputs__row {
  display: flex;
  justify-content: space-between;
  min-width: 500px;
}

.text--red {
  color: $color-red;
}

input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

.Input__field-container {
  position: relative;
  display: flex;
  align-items: center;
}

.Input {
  flex: 1;
  padding-right: 40px;
}

.Input__toggle-icon {
  position: absolute;
  right: 15px;
  cursor: pointer;
}
