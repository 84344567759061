// Colors
$main-bg: #f8f8f8;
$wrapper-bg: #fff;

$bg-black: #000;
$bg-black-secondary: #17181A;
$bg-white: #fff;
$bg-white-alpha-80: rgba(255,255,255,.8);
$bg-default: #f8f8f8;
$bg-red: #F95428;
$bg-red-light: #FCF4ED;
$bg-green: #B7D154;
$bg-green-light: #eff2df;
$bg-donation-side: #104368;
$bg-light-pink: #fcf4ed;
$bg-blue: #2b4567;
$bg-gray: #808D96;
$bg-gray-input: #efeff0;
$bg-gray-light: #F1F1F1;
$bg-cyan: #cee1e8;
$bg-cyan-light: #192224;
$bg-navy-blue: #024269;
$bg-white-blue: #cee1e8;
$bg-coral: #FF8977;

//Children card background colors
$bg-children-1: #ffb6b5;
$bg-children-2: #92cec6;
$bg-children-3: #ffe6d7;
$bg-children-4: #d6d5f5;
$bg-children-5: #c6d8da;
$bg-children-6: #f5e6e3;

$color-black: #000;
$color-black-secondary: #17181A;
$color-red: #F95428;
$color-green: #B7D154;
$color-white: #fff;
$color-lighter-pink: #fbf6ef;
$color-blue: #2b4567;
$color-gray: #808D96;
$color-gray-secondary: #dbdbdb;
$color-gray-table: #616367;
$color-info: #1781e0;
$color-navy-blue: #024269;

$transition-ease: all 0.2s ease;

$box-shadow: 0 0 15px rgba(0, 0, 0, 0.10);
$box-shadow-hover: 0 0 15px rgba(183, 209, 84, 0.3);
$box-shadow-card: 0 0 20px rgba(0,0,0, 0.1);
$box-shadow-button: 0 5px 20px rgba(0,0,0, 0.1);

// Typography

$sourcesanspro-regular: 'Source Sans Pro';
$sourcesanspro-bold: 'Source Sans Pro Bold';
$sourcesanspro-black: 'Source Sans Pro Black';
$sourcesanspro-italic: 'Source Sans Pro Italic';

// Extended Spacing
