.MobileMenu {
  position: fixed;
  top: 80px;
  left: 0;
  height: 0;
  width: 100%;
  background: #fff;
  overflow-y: scroll;
  transition: 0.5s;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  z-index: 900000;

  &--visible {
    height: calc(100vh - 80px);
  }

  .MobileMenu__bottom {
    width: 100%;
    margin: 30px 0;
    display: flex;
    justify-content: flex-end;

    button {
      min-width: 170px;
      width: 170px;
      margin: 7px auto;
      text-align: center;
    }

    .button {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 10px 20px;
      text-align: center;
    }
  }
}
