@import 'antd/dist/antd.css';
@import './bootstrap-grid.min.css';
@import './reset';
@import './variables';
@import './button';
@import './breakpoints';

body {
  margin: 0;
  font-family: 'Source Sans Pro', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px;
}

.fill-green {
  fill: $color-green;
}

img {
  max-width: 100%;
  object-fit: contain;

  &.img {
    &--full {
      width: 100%;
    }
    &--round {
      border-radius: 20px;
    }
  }
}

.title {
  color: $color-navy-blue;
  font-size: 28px;
  font-weight: 700;
  line-height: 1.1;
  letter-spacing: 2.2px;
  padding: 1.5rem 0;
  margin: 1rem 0;
  text-align: center;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    width: 100px;
    max-width: 60%;
    height: 4px;
    border-radius: 5px;
    background-color: $color-navy-blue;
    top: 0;
    left: calc(50% - 50px);
  }

  &-wrapper {
    width: 90%;
    padding: 1.5rem 0 1rem 0;
    margin: 0 auto;

    &.title--tailed {
      position: relative;
      &::before {
        z-index: -1;
        position: absolute;
        content: '';
        width: 100%;
        height: 200px;
        bottom: -200px;
        left: 0;
        background-color: inherit;
      }
    }

    &.bg--green-light {
      background-color: $bg-green-light;
    }

    &.bg--red-light {
      background-color: $bg-red-light;
    }
  }

  @media (min-width: $mobile-lg) {
    font-size: 36px;
  }

  @media (min-width: $screen-md) {
    font-size: 48px;
  }

  @media (min-width: $screen-lg) {
    font-size: 60px;
  }
}

h2.default-title {
  color: #024269;
  font-size: 42px;
  font-weight: 700;
  padding: 30px 0;
  line-height: 1.2;
}

.section-title {
  color: $color-navy-blue;
  font-size: 24px;
  font-weight: 700;
  line-height: 1.1;
  padding: 1rem 0 1rem 0;
  padding-left: 40px;
  margin-bottom: 0;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    width: 30px;
    max-width: 60%;
    height: 4px;
    border-radius: 5px;
    background-color: $color-navy-blue;
    top: calc(50% - 2px);
    left: 0;
    @media (min-width: $mobile-lg) {
      width: 60px;
    }

    @media (min-width: $screen-md) {
      width: 100px;
    }
  }

  @media (min-width: $mobile-lg) {
    margin-bottom: 1rem;
    font-size: 28px;
    padding-left: 80px;
  }

  @media (min-width: $screen-md) {
    margin-bottom: 2rem;
    font-size: 36px;
    padding-left: 120px;
  }

  @media (min-width: $screen-lg) {
    font-size: 48px;
    padding-left: 120px;
  }
}

p {
  font-size: 16px;
  letter-spacing: 0.5px;
  line-height: 1.28;
  color: $color-black-secondary;
  margin-bottom: 1rem;

  @media (min-width: $screen-sm) {
    font-size: 20px;
  }

  span {
    font-size: inherit;
  }
}

.br-30 {
  border-radius: 30px;
}

.dots {
  position: absolute;
  content: '';
  z-index: -1;
  background-image: url('../assets/images/svg/dots.svg');
  max-height: 100%;
  max-width: 100%;

  &.dots-pic-left {
    width: 280px;
    height: 360px;

    left: 0;
    bottom: 0;
  }

  &.dots-pic-right {
    width: 280px;
    height: 360px;

    right: 0;
    bottom: 0;
  }
}

.position-relative {
  position: relative;
}

.position-absolute {
  position: absolute;
}

.bold {
  font-weight: 700;
}

.default-title {
  font-weight: 700;
  font-size: 28px;
  color: $color-navy-blue;
  line-height: 1.1;

  @media (min-width: $screen-sm) {
    font-size: 36px;
  }

  @media (min-width: $screen-md) {
    font-size: 42px;
  }
}

.bold {
  font-weight: 700;
}

.color-gray {
  color: $color-gray;
}

.color-black {
  color: $color-black;
}

.color-navy-blue {
  color: $color-navy-blue;
}

.color-green {
  color: $color-green;
}

.color-red {
  color: $color-red;
}

.block--green-bordered {
  position: relative;
  padding-left: 30px;
  margin-left: 20px;
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 5px;
    height: 100%;
    width: 4px;
    border-radius: 5px;
    background-color: $bg-green;
  }
  @media (max-width: $screen-sm) {
    margin-left: 0px;
  }
}

.underline {
  text-decoration: underline;
}

sup {
  font-size: 100%;
}

.cursor-pointer {
  cursor: pointer;
}

div.custom-collapse-arrow {
  width: 15px;
  height: 15px;
  border-bottom: 2px solid #fff;
  border-left: 2px solid #fff;
  border-radius: 2px;
  transition: 200ms;
  transform: rotate(-45deg);
  &.rotated {
    transform: rotate(135deg);
  }
}
